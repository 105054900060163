html,
body {
  padding: 0;
  margin: 0;
  font-family: DINPro, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

@font-face {
  font-family: 'DINPro';
  src: url('/fonts/DINPro-Regular.woff2') format('woff2'), url('/fonts/DINPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro-bold';
  src: url('/fonts/DINPro-Bold.woff2') format('woff2'), url('/fonts/DINPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro-medium';
  src: url('/fonts/DINPro-Medium.woff2') format('woff2'), url('/fonts/DINPro-Medium.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
